// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-index-jsx": () => import("./../../../src/pages/about-us/index.jsx" /* webpackChunkName: "component---src-pages-about-us-index-jsx" */),
  "component---src-pages-about-us-our-team-jsx": () => import("./../../../src/pages/about-us/our-team.jsx" /* webpackChunkName: "component---src-pages-about-us-our-team-jsx" */),
  "component---src-pages-about-us-philanthropy-jsx": () => import("./../../../src/pages/about-us/philanthropy.jsx" /* webpackChunkName: "component---src-pages-about-us-philanthropy-jsx" */),
  "component---src-pages-about-us-reviews-jsx": () => import("./../../../src/pages/about-us/reviews.jsx" /* webpackChunkName: "component---src-pages-about-us-reviews-jsx" */),
  "component---src-pages-apply-for-credit-jsx": () => import("./../../../src/pages/apply-for-credit.jsx" /* webpackChunkName: "component---src-pages-apply-for-credit-jsx" */),
  "component---src-pages-bed-finder-jsx": () => import("./../../../src/pages/bed-finder.jsx" /* webpackChunkName: "component---src-pages-bed-finder-jsx" */),
  "component---src-pages-bed-sizes-jsx": () => import("./../../../src/pages/bed-sizes.jsx" /* webpackChunkName: "component---src-pages-bed-sizes-jsx" */),
  "component---src-pages-bed-specials-jsx": () => import("./../../../src/pages/bed-specials.jsx" /* webpackChunkName: "component---src-pages-bed-specials-jsx" */),
  "component---src-pages-black-friday-bed-sale-jsx": () => import("./../../../src/pages/black-friday-bed-sale.jsx" /* webpackChunkName: "component---src-pages-black-friday-bed-sale-jsx" */),
  "component---src-pages-cancellations-refunds-jsx": () => import("./../../../src/pages/cancellations-refunds.jsx" /* webpackChunkName: "component---src-pages-cancellations-refunds-jsx" */),
  "component---src-pages-checkout-jsx": () => import("./../../../src/pages/checkout.jsx" /* webpackChunkName: "component---src-pages-checkout-jsx" */),
  "component---src-pages-compare-products-jsx": () => import("./../../../src/pages/compare-products.jsx" /* webpackChunkName: "component---src-pages-compare-products-jsx" */),
  "component---src-pages-contact-us-contact-request-received-jsx": () => import("./../../../src/pages/contact-us/contact-request-received.jsx" /* webpackChunkName: "component---src-pages-contact-us-contact-request-received-jsx" */),
  "component---src-pages-contact-us-index-jsx": () => import("./../../../src/pages/contact-us/index.jsx" /* webpackChunkName: "component---src-pages-contact-us-index-jsx" */),
  "component---src-pages-cyber-monday-bed-sale-jsx": () => import("./../../../src/pages/cyber-monday-bed-sale.jsx" /* webpackChunkName: "component---src-pages-cyber-monday-bed-sale-jsx" */),
  "component---src-pages-delivery-jsx": () => import("./../../../src/pages/delivery.jsx" /* webpackChunkName: "component---src-pages-delivery-jsx" */),
  "component---src-pages-hospitality-beds-and-mattresses-index-jsx": () => import("./../../../src/pages/hospitality-beds-and-mattresses/index.jsx" /* webpackChunkName: "component---src-pages-hospitality-beds-and-mattresses-index-jsx" */),
  "component---src-pages-how-is-mattress-firmness-measured-jsx": () => import("./../../../src/pages/how-is-mattress-firmness-measured.jsx" /* webpackChunkName: "component---src-pages-how-is-mattress-firmness-measured-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-mattress-finder-jsx": () => import("./../../../src/pages/mattress-finder.jsx" /* webpackChunkName: "component---src-pages-mattress-finder-jsx" */),
  "component---src-pages-my-account-jsx": () => import("./../../../src/pages/my-account.jsx" /* webpackChunkName: "component---src-pages-my-account-jsx" */),
  "component---src-pages-order-redirect-jsx": () => import("./../../../src/pages/order-redirect.jsx" /* webpackChunkName: "component---src-pages-order-redirect-jsx" */),
  "component---src-pages-order-status-jsx": () => import("./../../../src/pages/order-status.jsx" /* webpackChunkName: "component---src-pages-order-status-jsx" */),
  "component---src-pages-payment-providers-jsx": () => import("./../../../src/pages/payment-providers.jsx" /* webpackChunkName: "component---src-pages-payment-providers-jsx" */),
  "component---src-pages-register-jsx": () => import("./../../../src/pages/register.jsx" /* webpackChunkName: "component---src-pages-register-jsx" */),
  "component---src-pages-reset-jsx": () => import("./../../../src/pages/reset.jsx" /* webpackChunkName: "component---src-pages-reset-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-singles-day-bed-sale-jsx": () => import("./../../../src/pages/singles-day-bed-sale.jsx" /* webpackChunkName: "component---src-pages-singles-day-bed-sale-jsx" */),
  "component---src-pages-sitemap-jsx": () => import("./../../../src/pages/sitemap.jsx" /* webpackChunkName: "component---src-pages-sitemap-jsx" */),
  "component---src-pages-slumberland-tiger-bed-competition-jsx": () => import("./../../../src/pages/slumberland-tiger-bed-competition.jsx" /* webpackChunkName: "component---src-pages-slumberland-tiger-bed-competition-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */),
  "component---src-templates-branch-page-jsx": () => import("./../../../src/templates/BranchPage.jsx" /* webpackChunkName: "component---src-templates-branch-page-jsx" */),
  "component---src-templates-faq-page-jsx": () => import("./../../../src/templates/FaqPage.jsx" /* webpackChunkName: "component---src-templates-faq-page-jsx" */),
  "component---src-templates-product-page-jsx": () => import("./../../../src/templates/ProductPage.jsx" /* webpackChunkName: "component---src-templates-product-page-jsx" */),
  "component---src-templates-shop-page-jsx": () => import("./../../../src/templates/ShopPage.jsx" /* webpackChunkName: "component---src-templates-shop-page-jsx" */),
  "component---src-templates-word-press-blog-jsx": () => import("./../../../src/templates/WordPressBlog.jsx" /* webpackChunkName: "component---src-templates-word-press-blog-jsx" */),
  "component---src-templates-word-press-post-jsx": () => import("./../../../src/templates/WordPressPost.jsx" /* webpackChunkName: "component---src-templates-word-press-post-jsx" */)
}

